import { PageTypes, ThemeContext } from "assets";
import { graphql } from "gatsby";
import {
  addExhibitionsParent,
  convertBreadcrumbNavigation,
  convertHero,
} from "molecules";
import moment from "moment";
import { convertComponentList, PageLayout } from "organisms";
import PropTypes from "prop-types";
import React from "react";

const DetailPage = ({ data }) => {
  const detailData = data?.craftAPI?.entry;

  const children = convertComponentList(detailData?.componentList);

  // Determine the detail number
  const { endDate, startDate, theme } = detailData;

  let timespan;
  // if both a start date and an endate
  if (startDate && endDate) {
    const startYear = moment(startDate).format("YYYY");
    const endYear = moment(endDate).format("YYYY");
    timespan = startYear === endYear ? startYear : `${startYear} - ${endYear}`;
    // else if just a start date
  } else if (startDate && !endDate) {
    timespan = moment(startDate).format("YYYY");
    // else if just an end date
  } else if (!startDate && endDate) {
    timespan = moment(endDate).format("YYYY");
  }

  // Place the hero at the top of the detail
  children.unshift(
    convertHero({ subtitle: timespan, pageType: "detail", ...detailData })
  );

  children.unshift(
    convertBreadcrumbNavigation({
      pageTitle: detailData.title,
      parents: addExhibitionsParent(detailData.parent),
    })
  );

  return (
    <ThemeContext.Provider
      value={{
        theme,
        fontType: "",
      }}
    >
      <PageLayout
        coverImage={detailData.coverImage}
        pageType={PageTypes.DETAIL}
        shortDescription={detailData.shortDescription}
        theme={theme}
        title={detailData.title}
      >
        {children}
      </PageLayout>
    </ThemeContext.Provider>
  );
};

DetailPage.propTypes = {
  data: PropTypes.shape({
    craftAPI: PropTypes.shape({
      entry: PropTypes.shape({}),
    }),
  }),
};

export const DetailQuery = graphql`
  query ($uri: [String]) {
    craftAPI {
      entry(uri: $uri) {
        id
        title
        uri
        ...BreadcrumbNavigationFragment
        ... on CraftAPI_exhibit_detail_Entry {
          coverImage {
            ...ImageMetadataFragment
          }
          coverImageCropStyle
          coverImagePositionOverride
          componentList {
            ...ComponentListFragment
          }
          endDate
          heroType
          longDescription
          shortDescription
          startDate
          theme
        }
      }
    }
  }
`;

export default DetailPage;
